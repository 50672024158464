<template>
  <div class="cont">
    <el-row class="content-box">
      <el-row>
        <el-link type="primary" class="back_iconfont" icon="iconfont iconfanhui1" @click="go_back()">
          <span v-text="macAddressVal" style="font-size: 20px;"></span>
        </el-link>
        <div class="header-right">
          <p v-text="devTypeVal" class="box-pad"></p>
        </div>
      </el-row>
      <!-- 设备详情 -->
      <div class="det-box">
        <el-divider content-position="left"><span v-text="$t('msg.dev_info')">设备信息</span></el-divider>
        <ul>
          <li v-if="deviceTypeId === '100'">
            <span v-text="$t('msg.sync_dev_a')">是否同步子设备：</span>
            <el-button-group>
              <el-button :type="syncDev === 'YES' ? 'primary': ''"  @click="syncDev = 'YES'"><span v-text="$t('msg.yes')">是</span></el-button>
              <el-button :type="syncDev === 'YES' ? '': 'primary'"  @click="syncDev = 'NO'"><span v-text="$t('msg.no')">否</span></el-button>
            </el-button-group>
          </li>
          <li>
            <span v-text="$t('msg.dev_alias_a')">设备别名：</span>
            <el-input  class="width-193" type="text" v-model="devAliasVal" :placeholder="$t('msg.dev_alias')"></el-input>
          </li>
          <li>
            <span v-text="$t('msg.install_location_a')">安装位置：</span>
            <el-select  class="width-193" v-model="locationVal" :placeholder="$t('msg.select')">
              <el-option v-for="item in installLocations" :key="item.value" :label="item.label" :value="item.value"></el-option>
            </el-select>
          </li>
          <li v-if="productId === ('HODSZB' || 'HOMSZB' || 'HOGSZB' || 'HOSAZB')">
            <span v-text="$t('msg.push_messages_a')">是否推送消息：</span>
            <el-button-group>
              <el-button :type="pushMessagesVal === 'YES' ? 'primary': ''"  @click="pushMessagesVal = 'YES'"><span v-text="$t('msg.yes')">是</span></el-button>
              <el-button :type="pushMessagesVal === 'NO' ? '': 'primary'"  @click="pushMessagesVal = 'NO'"><span v-text="$t('msg.no')">否</span></el-button>
            </el-button-group>
          </li>
          <li v-if="isShowDeviceAttach">
            <span class="p-right-10" v-text="$t('msg.panel_brightness_a')">面板亮度：</span>
            <el-slider
            v-model="deviceAttach.onLevel"
            class="width-193"
            style="display: inline-block;height: 23px;"
            :min="0"
            :max="256"
            ></el-slider>
          </li>
          <li v-if="wheNormalVal">
            <span v-text="$t('msg.whe_power_a')">是否推送电量：</span>
            <el-button-group>
              <el-button :type="wheNormalVal === 'YES' ? 'primary': ''"  @click="wheNormalVal = 'YES'"><span v-text="$t('msg.yes')">是</span></el-button>
              <el-button :type="wheNormalVal === 'YES' ? '': 'primary'"  @click="wheNormalVal = 'NO'"><span v-text="$t('msg.no')">否</span></el-button>
            </el-button-group>
          </li>
          <li v-if="curDirectionVal">
            <span v-text="$t('msg.cur_direction_a')">窗帘转动方向：</span>
            <el-button-group>
              <el-button :type="curDirectionVal === 'FRONT' ? 'primary': ''"  @click="curDirectionVal = 'FRONT'"><span v-text="$t('msg.positive')">正</span></el-button>
              <el-button :type="curDirectionVal === 'FRONT' ? '': 'primary'"  @click="curDirectionVal = 'REVERSE'"><span v-text="$t('msg.anti')">反</span></el-button>
            </el-button-group>
          </li>
        </ul>
      </div>
      <!-- 设备按键列表 -->
      <div class="det-box" v-if="devData.length > 0">
        <el-divider content-position="left"><span v-text="$t('msg.device_list')">设备按键列表</span></el-divider>
        <el-table
                :data="devData"
                style="width: 100%"
                min-height="780"
                max-height="780"
                :stripe="true"
                >
          <el-table-column type="index" :label="$t('msg.key_position')" width="300"></el-table-column>
          <el-table-column prop="type" :label="$t('msg.key_type')">
            <template slot-scope="scope">
              <el-select  class="width-193" v-model="scope.row.type" :placeholder="$t('msg.select')">
                <el-option v-for="item in dEditkeyTypeOpt" :key="item.value" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column prop="alias" :label="$t('msg.key_alias')">
            <template slot-scope="scope">
              <el-input  class="width-193" type="text" v-model="scope.row.alias" :placeholder="$t('msg.key_alias')"></el-input>
            </template>
          </el-table-column>
          <el-table-column prop="isControlLight" :label="$t('msg.whe_connect')">
            <template slot-scope="scope">
              <el-switch
                      style="margin: 0px 5px;"
                      v-model="scope.row.isControlLight"
                      active-color="#67c23a"
                      inactive-color="#dcdfe6"
                      active-value="YES"
                      inactive-value="NO"
              >
              </el-switch>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <el-row class="row-buttom">
        <el-button type="primary" @click="handleSave()" v-text="$t('msg.save')">保存</el-button>
      </el-row>
    </el-row>
  </div>
</template>
<script>
   import { urlObj } from '@/api/interface'
   import { system } from '@/api/interface/system'
   import common from '@/common/js/common'
   export default {
      data () {
         return {
            installLocations: [],      // 安装位置
            isShowDeviceAttach: false,
            thisGatewayId: '',
            devType: '', // 设备类型(标记)
            devId: '', // 设备ID
            productId: '', // 类型ID
            devProductVal: '—', // 所属产品
            devTypeVal: '—', // 设备类型
            formatVal: '—', // 规格
            devModelVal: '—', // 设备型号
            devKeyVal: '—', // 设备Key
            devSecretVal: '—', // 设备秘钥
            devVersionVal: '—', // 设备版本
            descriptionVal: '—', // 型号描述
            agreementVal: '—', // 协议类型
            pushMessagesVal: 'NO', // 是否推送消息
            panelstartVal: '—', // 面板开始亮度
            panelendVal: '—', // 面板结束亮度
            panelData: [20,60],
            deviceAttach: false, // 面板
            curDirectionVal: '', // 窗帘转动方向
            wheNormalVal: '', // 电量是否正常
            whePowerVal: '', // 是否推送电量
            macAddressVal: '—', // MAC地址
            locationVal: '—', // 位置
            deviceTypeId: '—', // 所属网关Id
            ownGatewayId: '—', // 所属网关Id
            ownGatewayVal: '—', // 所属网关
            GatewayVersion: '—', // 网关版本
            lastTimeVal: '—', // 最后在线时间
            offTimeVal: '—', // 最后离线时间
            firstAliasVal: '—', // 第一位别名
            secondAliasVal: '—', // 第二位别名
            thirdAliasVal: '—', // 第三位别名
            fourthAliasVal: '—', // 第四位别名
            firstStatusVal: '—', // 第一位状态
            secondStatusVal: '—', // 第二位状态
            thirdStatusVal: '—', // 第三位状态
            fourthStatusVal: '—', // 第四位状态
            numFlag: 0,
            switchFlag: 0, // 判断是否是开关
            switchVal: [], // 开光状态
            aliasArrVal: [],
            statusArrVal: [],
            roomNoVal: '—', // 房间号
            devAliasVal: '', // 设备别名
            buildUnitVal: '', // 设备所属楼栋/单元
            syncDev: 'NO', // 是否同步子设备
            addTimeVal: '—', // 入网时间
            onlineStatusVal: '—', // 在线状态
            floorVal: '—', // 楼层
            thisStatus: 'OFFLINE', // 当前状态
            moreStatus: false, // 更多状态
            timeVal: '24', // 时间范围
            dateValue: '', // 日期
            timeShow: false, // 日期框(显示状态)
            seartDate: '', // 开始日期
            endDate: '', // 结束日期
            devData: [],
            controlData: [],
            baoziData: [],
            dEditkeyTypeOpt: [
               {
                  value: 'SWITCH',
                  label: '开关'
               },
               {
                  value: 'SCENE',
                  label: '场景'
               }
            ],
            statusData: [],
            dEditRoomNoOpt: [],
            buildingList: [],
            /* 提示文本 */
            dev_24_status: '设备24小时状态图',
            sel_time_range: '请选择时间范围！',
            no_state: '暂无状态！',
            confirm: '确定',
            update_success: '修改成功！',
            online_status_no_data: '设备24小时一直处于在线状态，无状态数据！',
            offline_status_no_data: '设备24小时一直处于离线状态，无状态数据！',
            open_s: '开',
            close_s: '关',
            yes: '是',
            no: '否',
            positive: '正',
            anti: '反',
            normal: '正常',
            abnormal: '异常',
         }
      },
      mounted () {
         this.hotelId = this.$route.query.hotelId
         this.getInstallLocations() // 设备位置
         this.device_info() // 设备信息
      },
      methods: {
         // 设备信息
         device_info() {
            let id = this.$route.query.id
            let t = this.$route.query.type
            let detUrl = urlObj.getHomeDevDetail
            this.devType = t
            this.devId = id
            let detParam = {
               deviceId: id
            }
            this.$axios.post(detUrl, detParam).then((res) => {
               if (res.success) {
                  let obj = res.records
                  this.devTypeVal = obj.versionsName
                  if (!obj.deviceNumber) {
                     this.devModelVal = 'HOS-GW-Pro1' // 设备型号
                  } else {
                     this.devModelVal = obj.deviceNumber
                  }
                  this.locationVal = obj.location // 位置
                  this.deviceTypeId = obj.deviceTypeId
                  this.ownGatewayId = obj.gatewayId
                  this.ownGatewayVal = obj.gatewayName
                  this.GatewayVersion = obj.versions
                  this.macAddressVal = obj.deviceName
                  this.devAliasVal = obj.alias // 设备别名
                  this.addTimeVal = obj.createTime
                  this.onlineStatusVal = obj.state
                  this.lastTimeVal = obj.lastOnlineTime
                  // 别名、状态
                  this.productId = obj.productId.toString().substring(0, 6)
                  let productName = obj.productId.toString().substring(0, 6)
                  let switchs = false
                  if (productName === 'HOSWZB') {
                     switchs = true
                  } else {
                     switchs = false
                  }
                  let deviceAttach = obj.deviceAttach
                  if (obj.productId === 'HOSWZB1100010000'){
                     this.isShowDeviceAttach = true
                  }
                  if (obj.deviceAttach) {
                     this.deviceAttach = deviceAttach
                     if(deviceAttach.isPushBattery){
                        this.wheNormalVal = deviceAttach.isPushBattery
                     }
                     if(deviceAttach.direction){
                        this.curDirectionVal = deviceAttach.direction
                     }
                  }
                  if (obj.syncChildRoom) {
                     this.syncDev = obj.syncChildRoom
                  }
                  this.pushMessagesVal = obj.isPush
                  let switchList = obj.switchList
                  if (switchList) {
                     this.devData = switchList
                  }
                  this.thisStatus = obj.state // OFFLINE(0)：离线、ONLINE(1)：在线、2：未激活
                  if (!obj.stateList) {
                     obj.stateList = []
                  }
               }
            }).catch(error => {
               console.log(error)
            })
         },
         // 获取设备位置
         getInstallLocations() {
            const url = system.subDict + '/device-location'
            this.$axios.get(url, {}).then((res) => {
               if (res.success) {
                  let installLocations = res.records
                  installLocations = installLocations.map(item => {
                     return {value: item.dictKey, label: item.dictVal}
                  })
                  this.installLocations = installLocations
               }
            }).catch(error => {
               console.log(error)
            })
         },
         // 更新设备信息
         handleSave() {
            const url = urlObj.updateHomeDeviceForPc
            let param = {
               id: this.devId, // 设备ID
               alias: this.devAliasVal, // 设备别名
               location: this.locationVal, // 安装位置
            }
            if (this.devData.length > 0) {
               param.switchs = JSON.stringify(this.devData)
            }
            if (this.deviceAttach) {
               param.attach = this.deviceAttach
            }
            if (this.whePowerVal) {
               param.attach.isPushBattery = this.whePowerVal
            }
            if (this.curDirectionVal) {
               param.attach.direction = this.curDirectionVal
            }
            if (this.deviceTypeId === '100') {
               param.syncChildRoom = this.syncDev
            }
            if (param.attach) {
               param.attach = JSON.stringify(param.attach)
            }
            this.$axios.post(url, param).then((res) => {
               if (res.success) {
                  this.$message({
                     showClose: true,
                     message: this.update_success,
                     type: 'success'
                  })
               }
            }).catch(error => {
               console.log(error)
            })
         },
         // 返回(设备管理)
         go_back () {
            sessionStorage.setItem('childDetail', 'false')
            this.$router.back(-1)
         },
      }
   }
</script>
<style scoped lang="scss">
  .cont{
    min-width: 1120px;
    .box-span{
      vertical-align: middle;
      margin-left: 10px;
    }
    .row-buttom{
      text-align: right;
      padding: 20px;
    }
    /deep/.el-input__inner{
      line-height: 30px;
      height: 30px;
    }
  }
  .el-row{
    line-height: 30px;
    white-space: nowrap;
  }
  .header-right{
    float: right;
    width: 40%;
    text-align: right;
    p{
      display: inline-block;
      margin: 0 10px;
    }
    .box-pad{
      color: #00BFBF;
      border: 1px solid rgba(0, 191, 191, 1);
      background: rgba(0, 191, 191, 0.2);
      padding: 0 10px;
    }
  }
  .det-box{
    /* border: 1px solid red; */
    background: white;
    padding: 10px 20px;
    // margin-bottom: 15px;
  }
  .det-box::after{
    content: "";
    display: block;
    clear: both;
  }
  .det-box > ul{
    list-style: none;
  }
  .det-box > ul > li{
    float: left;
    width: 33.3%;
    height: 40px;
    margin-bottom: 20px;
    font-size: 14px;
    white-space: nowrap;
  }
  .det-box > ul > li > span:first-child{
    display: inline-block;
    min-width: 140px;
    text-align: right;
  }
  // .ech-box{
  //   border: 1px solid red;
  //   margin-top: 15px;
  // }
  .ech-item{
    height: 300px;
    /* border: 1px solid red; */
    background: white;
    border-radius: 10px;
    position: relative;
    padding-bottom: 30px;
    padding-right: 30px;
  }
  .ech-item > .el-link{
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 1;
  }
  #status-ech{
    height: 100%;
    width: 100%;
  }
  .txt-box{
    position: absolute;
    top: 46px;
    left: 12px;
    line-height: 110px;
  }
  .hour-box{
    position: absolute;
    right: 10px;
    bottom: 50px;
  }
  .gateway_url{
    color: #409EFF;
  }
  .gateway_url:hover{
    color: #1ABC9C;
    cursor: pointer;
    border-bottom: 1px solid #409EFF;
  }
</style>
